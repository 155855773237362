* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
}



.logo h2 {
    margin-top: 1rem;
    font-size: 4.5rem;
    font-weight: 400;




}

.main-nav {
    width: 100%;
    height: 6.5rem;

    box-shadow: 10px 5px 5px grey;
    background-color: black;
    color: white;
    position: sticky;
    display: grid;
    grid-template-columns: 10rem 1fr 1fr 10rem;

}



::placeholder {

    font-size: 1.6rem;
}




.logo {

    grid-column: 2/3;
    justify-content: center;

}



.logo h2 {
    position: relative;
    top: .3rem;
    font-size: 3rem;
    font-weight: 500;

}




.middlesection {

    position: relative;
    bottom: 2.3rem;
    top: 2rem;
    padding: 1rem 0 1rem 18rem;
}

.heading {

    font-size: 2.3rem;

}

.filter {
    display: flex;
    justify-content: space-between;
    margin: 1.5rem 0 1.5rem 0;
    width: 80%;

}

select[type=text] {
    padding: 7px 16px;
    border-radius: 4px;
    background-color: whitesmoke;
}

.search_class {

    position: relative;
    left: 17rem;
}



.search_class input[type=text] {
    width: 17rem;
    height: 3.3rem;
    border-radius: 8px;
    padding: 12px 16px;
    background-color: whitesmoke;
    border: none;
}

.search_span {
    font-size: 2rem;
}


.cart-item2 {
    background-color: whitesmoke;
    width: 80vw;
    display: grid;
    place-items: center;


}

.cart-item-containers {
    padding-bottom: 2rem;
    width: 90%;
    height: 56rem;
    position: relative;
    border: 1px inset #80808036;
    margin-top: 1rem;
    overflow: hidden;
}

.cart_title {
    background-color: whitesmoke;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    margin: auto;
    place-items: center;
    height: 4rem;
    margin-bottom: 1rem;
    border-bottom: 2px solid black;
}

.items-info1 {
    display: grid;
    grid-template-columns: repeat(6, 1fr);

    position: relative;


    width: 100%;
    height: 11rem;
    place-items: center;


}

.product-img {
    margin: 1rem 0 1rem 0;
}

.product-img1 img {
    height: 11rem;
    width: 15rem;
    border-radius: .6rem;
    filter: drop-shadow(0.2rem 0.4rem 0.3rem grey);


}

.title {
    margin: 1rem 0;
    padding: 1.7rem 0 0 0;
}

.add-minus-quantity {
    margin: 1rem 0;
    padding: 1.9rem 0 0 0;

}


.title h2 {
    font-size: 1.8rem;
}

input[type=text] {
    width: 40px;
    height: 20px;
    margin: 0 1rem;
    padding: 1rem auto;
    border: 1px solid black;

}

::placeholder {
    padding: 1rem;
    text-align: center;
    color: black;
}



.price {

    margin: 3rem 0 0 0;
    font-size: 1.5rem;

}

.remove-item {
    margin: 3rem 0 0 0;

}


.fa-cart-shopping {
    font-size: 1.6rem;
    color: white;
    padding: 7px 20px;
    background-color: black;
    margin-right: 1rem;
}

.hr1 {
    display: flex;

    width: 94%;
    margin: 2rem auto;
    overflow-y: scroll;
}

.total-cart {

    display: flex;



    width: 80vw;
    height: 9rem;



}










.btn_clear1 {
    padding: 12px 30px;
    border-radius: .5rem;
    background-color: red;
    font-size: 1.6rem;
    font-weight: bold;
    color: white;
    border: none;
}